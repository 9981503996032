import axios from "axios"
import useJwt from '@src/auth/jwt/useJwt'

export const imgUrl = `https://api-staging.areakart.in`
// export const imgUrl = `http://localhost:3080`

// export const imgUrl = `https://api.areakart.com`
// export const imgUrl = `https://api-staging.areakart.in`
// export const imgUrl = `https://api.areakart.com`
// export const imgUrl = `https://api-staging.areakart.in`
// export const imgUrl  = `http://192.168.20.16:3080`

const axiosInstance = axios.create({ baseURL: `${imgUrl}/` })

const config = useJwt.jwtConfig

axiosInstance.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('accessToken')
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`
        }
        return config
    },
    error => {
        console.log("interceptor")
        console.log(error)
        Promise.reject(error)
    })


 axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.log("interceptor")
        console.log(error)
        if (error.response.status === 401) {
            localStorage.removeItem('userData')
            localStorage.removeItem('storeId')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
            window.location.href = `${process.env.PUBLIC_URL}/login`
        }
    })

export default axiosInstance

